import React, {useEffect, useState} from 'react';
import Monto from "../Monto";
import Precio from "../Precio";
import Rendimiento from "../Rendimiento";
import Sector from "../Sector";
import Loader from "../Loader";
import Api from "../Api";
import ExportTable from "../ExportTable";
const api = new Api();

const OfertasResultados = ({subasta,tipoOfertas}) => {

    const [ofertas, setOfertas] = useState([]);
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        setShowLoader(true);
        api.get(`/resultados_subasta_ofertas/${subasta?.TASU_IDSUBASTA}/${tipoOfertas}`).then((res)=> {
            setShowLoader(false);
            setOfertas(res);
        });

        return () => {

        };
    }, [subasta]);

    return (
        <>
            <Loader active={showLoader}></Loader>
            <ExportTable fileName={"OfertasResultados_"+subasta.TATE_EMISION+".csv"} >
                <table className="table">
                    <thead>
                    <tr>
                        <th className="col-1 text-left">Id</th>
                        <th className="col-1 text-left">Monto Recibido</th>
                        <th className="col-1 text-left">Rendimiento</th>
                        <th className="col-1 text-left">Precio</th>
                        <th className="col-1 text-left">Negociado</th>
                        <th className="col-1 text-left">Recibido Acumulado</th>
                        <th className="col-1 text-left">Negociado Acumulado</th>
                        <th className="col-1 text-left">XI*PI</th>
                        <th className="col-1 text-left">XI*PI Acumulado</th>
                        <th className="col-1 text-left">Precio Ponderado</th>
                        <th className="col-1 text-left">Sector</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        !ofertas.length ?  (<tr><td className="text-left font-weight-bolder text-uppercase" colSpan={"3"}>No existen Ofertas para el criterio de busqueda.</td></tr>) : (<tr className="d-none"><td colSpan={"3"}></td></tr>)
                    }
                    {
                        ofertas.map((oferta,key)=> {
                            return (
                                <tr key={'s'+key}>
                                    <td className="text-left">{oferta.TACV_CODCASA}-{oferta.TASE_IDOFERTA}</td>
                                    <td className="text-left">{<Monto tipoEmision={subasta.TATE_TIPEMISION} value={oferta.TASE_ADJUDICADO}/>}</td>
                                    <td className="text-left">{<Rendimiento value={oferta.TASE_RENDIMIENTO}/>}</td>
                                    <td className="text-left">{<Precio tipoEmision={subasta.TATE_TIPEMISION} value={oferta.TASE_PRECIO}/>}</td>
                                    <td className="text-left">{<Monto tipoEmision={subasta.TATE_TIPEMISION} value={oferta.TASE_NEGOCIADO}/>}</td>
                                    <td className="text-left">{<Monto tipoEmision={subasta.TATE_TIPEMISION} value={oferta.TASE_ADJUDICADO_ACUMULADO}/>}</td>
                                    <td className="text-left">{<Monto tipoEmision={subasta.TATE_TIPEMISION} value={oferta.TASE_NEGOCIADO_ACUMULADO}/>}</td>
                                    <td className="text-left">{<Monto tipoEmision={subasta.TATE_TIPEMISION} value={oferta.TASE_XIPI}/>}</td>
                                    <td className="text-left">{<Monto tipoEmision={subasta.TATE_TIPEMISION} value={oferta.TASE_XIPI_ACUMULADO}/>}</td>
                                    <td className="text-left">{<Precio tipoEmision={subasta.TATE_TIPEMISION} value={oferta.TASE_PRECIO_PONDERADO}/>}</td>
                                    <td className="text-left">{<Sector value={oferta.TAOF_SECTOR}/>}</td>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </table>
            </ExportTable>
        </>
    );
};

export default OfertasResultados;
