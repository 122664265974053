import React, {useEffect} from 'react'
import './Header.scss'
import {Link} from 'react-router-dom';

const Header = ({logo}) => {

    useEffect(()=> {

    },[logo])

    return (
        <div className="Header">
            <nav className="navbar navbar-expand-lg navbar-light">
                {logo}
                <div className="collapse navbar-collapse" id="navbarText">
                    <div className="navbar-nav mr-auto"></div>
                    <span className="navbar-text">
                        <ul className="navbar-nav mr-auto">
                            <li className="nav-item active mr-4">
                                <Link className="nav-link" to="/">Inicio <span className="sr-only">(current)</span></Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/">Sistema de Subastas</Link>
                            </li>
                        </ul>
                    </span>
                </div>
            </nav>
        </div>
    );
};

export default Header;