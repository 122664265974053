import React,{useEffect, useRef} from 'react';

const ExportTable = ({children, fileName = "Subastas.csv"}) => {

    const exporter = useRef(null);

    const downloadCSVFile = (csv, filename)=> {
        var csv_file, download_link;
        csv_file = new Blob([csv], {type: "text/csv"});
        download_link = document.createElement("a");
        download_link.download = filename;
        download_link.href = window.URL.createObjectURL(csv_file);
        download_link.style.display = "none";
        document.body.appendChild(download_link);
        download_link.click();
    }

    const exportCSV = ()=> {
        let data = [];

        const rows = exporter.current.querySelector('table').getElementsByTagName('tr')

        for (var i = 0; i < rows.length; i++) {
            var row = [], cols = rows[i].querySelectorAll("td, th");

            for (var j = 0; j < cols.length; j++) {
                let col = cols[j].innerText.replaceAll(',','').replaceAll('\n',' ');
                row.push(col);
            }

            data.push(row.join(","));
        }

        downloadCSVFile(data.join("\n"), fileName);
    }

    useEffect(() => {
        return () => {

        };
    }, []);


    return (
        <div className="w-100" ref={exporter}>
            <div className={"p-0"}>
                <button className={"float-right mb-2 btn btn-primary btn-small"} onClick={exportCSV}>Exportar CSV</button>
            </div>

            {children}

        </div>
    );
};

export default ExportTable;
