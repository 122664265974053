import React, {useEffect} from 'react';
import logoImg from './logo-latinex.svg'
import {Link} from "react-router-dom";


const Logo = ({style, className, link = '/'}) => {
    useEffect(()=>{

    },[style,className]);

    return (
        <Link to={link}><img style={style} className={"logo "+className} src={logoImg} alt="Logo" /></Link>
    );
};


export default Logo;