import React, {useEffect, useState} from 'react';
import Loader from "../Loader";
import Api from "../Api";
import ExportTable from "../ExportTable";
import OfertasRecibidas from "./OfertasRecibidas";
const api = new Api();

const SubastasActivas = () => {
    const [subastas, setSubastas] = useState([]);
    const [showLoader, setShowLoader] = useState(false);

    const [subataSelecionada, setSubataSelecionada] = useState(0);
    const [showOfertModal, setShowOfertModal] = useState(false);
    const [showDetalleModal, setShowDetalleModal] = useState(false);

    const mostrarOfertas = (subasta)=> {
        setShowOfertModal(true);
        setSubataSelecionada(subasta);
    }

    useEffect(()=> {
        setShowLoader(true);
        api.get(`/subastas_realizadas/${new Date().getFullYear()}/A/2`).then((res)=> {
            setShowLoader(false);
            setSubastas(res);
        });
    },[]);

    return (
        <div className="activas">
            <Loader active={showLoader}></Loader>
            <div className="container mt-4">
                <div className="row mt-4">
                    <ExportTable fileName={"SubastasActivas.csv"}>
                        <table className="table">
                            <thead>
                            <tr>
                                <th className="col-1 text-left">Fecha</th>
                                <th className="col text-left">Subasta</th>
                                <th className="col-2"></th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                !subastas.length ? (<tr><td className="text-left font-weight-bolder text-uppercase" colSpan={"3"}>Actualmente no existen subastas activas</td></tr>) : (<tr className="d-none"><td colSpan={"3"}></td></tr>)
                            }

                            {
                                subastas.map((subasta,key)=> {
                                    return (
                                        <tr key={'s'+key}>
                                            <td className="text-left">{subasta.TASU_FECSUBASTA}</td>
                                            <td className="text-left">
                                                <b>{subasta.TATE_EMISION}</b><br/>
                                                <b>Fecha de Emisión: <span className="font-weight-light">{subasta.FECHA_EMISION}, </span> </b>
                                                <b>Fecha de Vencimiento: <span className="font-weight-light">{subasta.FECHA_VENCIMIENTO}, </span></b>
                                                <b>Fecha de Liquidación: <span className="font-weight-light">{subasta.FECHA_LIQUIDACION} </span> </b>
                                                <span className="d-block font-weight-lighter">{subasta.REFERENCIA}</span>
                                            </td>
                                            <td className="text-left">
                                                <div className="btn-group d-none d-lg-flex" role="group" aria-label="Basic example">
                                                    <button type="button" className="btn btn-primary btn-sm mr-1" onClick={()=> {mostrarOfertas(subasta)}}>Ofertas</button>
                                                    <button type="button" className="btn btn-dark btn-sm">Resultados</button>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </table>
                    </ExportTable>
                </div>
            </div>
            <OfertasRecibidas show={showOfertModal} onClose={()=> {setShowOfertModal(false)}} subasta={subataSelecionada} ></OfertasRecibidas>
        </div>
    );
};

export default SubastasActivas;
