import React from 'react';

const Precio = ({value,tipoEmision}) => {
    return (
        <>
            {(tipoEmision === 'A') ? ('B/. '+Number(value).toFixed(2)) : (Number(value).toFixed(2) + '%')}
        </>
    );
};


export default Precio;
