import React, {useEffect, useState} from 'react';
import {Modal} from 'react-bootstrap';
import Api from "../Api";
import Loader from "../Loader";
import Logo from "../Logo";
import './index.scss';
import Monto from "../Monto";
import Precio from "../Precio";
import ExportTable from "../ExportTable";
const api = new Api();


const OfertasRecibidas = ({subasta, show, onClose}) => {
    const [showLoader, setShowLoader] = useState(false);
    const [ofertas, setOfertas] = useState([]);

    useEffect(() => {
        if(subasta) {
            setShowLoader(true);
            setOfertas([]);
            api.get('/ofertas_recibidas/'+subasta?.TASU_IDSUBASTA).then((res)=> {
                setShowLoader(false);
                if(res.length) {
                    setOfertas(res);
                }
            });
        }
        return () => {

        };
    }, [subasta]);
    
    return (
        <div>
            <Loader active={showLoader}></Loader>
            <Modal backdropClassName={"modal-backdrop-fullscreen"} dialogClassName={"modal-fullscreen OfertasRecibidas"} show={show} onHide={onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div className="row">
                            <div className="col-lg-4 col-sm-12">
                                <Logo link="/" style={{width:'350px', left:'-27px', position:'relative'}} />
                            </div>
                            <div className="col-lg-8 col-sm-12">
                                <div>Ofertas Recibidas:</div>
                                <div style={{fontSize:'14px'}}>
                                    <b>{subasta?.TATE_EMISION}</b><br/>
                                    <b>Fecha de Emisión: <span className="font-weight-light">{subasta?.FECHA_EMISION}, </span> </b>
                                    <b>Fecha de Vencimiento: <span className="font-weight-light">{subasta?.FECHA_VENCIMIENTO}, </span></b>
                                    <b>Fecha de Liquidación: <span className="font-weight-light">{subasta?.FECHA_LIQUIDACION} </span> </b>
                                    <span className="d-block font-weight-lighter">{subasta?.REFERENCIA}</span>
                                </div>
                            </div>
                        </div>


                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ExportTable fileName={"OfertasRecividas_"+subasta.TATE_EMISION+".csv"}>
                        <table className="table">
                            <thead>
                            <tr>
                                <th className="col-1 text-left">Proponente Competitiva</th>
                                <th className="col-1 text-left">Ofertas Competitivas</th>
                                <th className="col-1 text-left">Precio</th>
                                <th className="col-2 text-left">Proponente No Competitiva Privada</th>
                                <th className="col-2 text-left">Oferta No Competitiva Privada</th>
                                <th className="col-2 text-left">Proponente No Competitiva Publica</th>
                                <th className="col-2 text-left">Oferta No Competitiva Publica</th>
                                <th className="col-3 text-left">Nominal Acumulado</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                !ofertas.length ?  (<tr><td className="text-left font-weight-bolder text-uppercase" colSpan={"3"}>No existen subastas para el criterio de busqueda.</td></tr>) : (<tr className="d-none"><td colSpan={"3"}></td></tr>)
                            }
                            {
                                ofertas.map((oferta,key)=> {
                                    return (
                                        <tr key={'s'+key}>
                                            <td className="text-left">{oferta.PCOMP}</td>
                                            <td className="text-left">{<Monto tipoEmision={subasta.TATE_TIPEMISION} value={oferta.OFCOMP}/>}</td>
                                            <td className="text-left"><Precio value={oferta.PRECIO} tipoEmision={subasta.TATE_TIPEMISION}></Precio></td>
                                            <td className="text-left">{oferta.PNOCOMP}</td>
                                            <td className="text-left">{<Monto tipoEmision={subasta.TATE_TIPEMISION} value={oferta.OFNOCOMP}/>}</td>
                                            <td className="text-left">{oferta.PNOCOMPGOB}</td>
                                            <td className="text-left">{<Monto tipoEmision={subasta.TATE_TIPEMISION} value={oferta.OFNOCOMPGOB}/>}</td>
                                            <td className="text-left"><Monto tipoEmision={subasta.TATE_TIPEMISION} value={oferta.ACUMULADO}/></td>
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </table>
                    </ExportTable>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default OfertasRecibidas;
