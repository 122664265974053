import React, {useEffect, useState} from 'react';
import Api from "../Api";
import Loader from "../Loader";
import OfertasRecibidas from "./OfertasRecibidas";
import SubastasResultados from "./SubastasResultados";
import ExportTable from "../ExportTable";
const api = new Api();

const SubastasRealizadas = () => {
    const [fechaComboList, setFechaComboList] = useState({value:''});
    const [fechaList, setFechaList] = useState([]);
    const [subastas, setSubastas] = useState([]);
    const [subataSelecionada, setSubataSelecionada] = useState(0);
    const [showOfertModal, setShowOfertModal] = useState(false);
    const [showDetalleModal, setShowDetalleModal] = useState(false);

    const [showLoader, setShowLoader] = useState(false);


    const filtrarPorFecha = (fecha)=> {
        setShowLoader(true);
        api.get(`/subastas_realizadas/${fecha}/C/3`).then((res)=> {
            setShowLoader(false);
            setSubastas(res);
        });
    };

    const mostrarOfertas = (subasta)=> {
        setShowOfertModal(true);
        setSubataSelecionada(subasta);
    }

    const mostrarDetalle = (subasta)=> {
        setShowDetalleModal(true);
        setSubataSelecionada(subasta);
    }

    useEffect(()=> {
        setShowLoader(true);
        api.get('/subasta_year_list').then((res)=> {
            setShowLoader(false);
            setFechaList(res);
            setFechaComboList({value:res[0].YEAR});
            filtrarPorFecha(res[0].YEAR);
        });
    },[]);
    return (
        <>
            <div className="Resultados">
                <Loader active={showLoader}></Loader>
                <div className="container mt-4">
                    <div className="row">
                        <div className="col-12">
                            <form className="form-inline float-right">
                                <div className="form-group mx-3 mb-2">
                                    <label className="mr-2">Año: </label>
                                    <select className="form-control d-inline-block w-auto" value={fechaComboList.value} onChange={(event)=>{setFechaComboList({value:event.target.value})}}>
                                        {
                                            fechaList?.map((item, key)=> {
                                                return <option key={'y'+key} value={item.YEAR}>{item.YEAR}</option>
                                            })
                                        }
                                    </select>
                                </div>
                                <button type="button" className="btn btn-primary mb-2 btn-sm" onClick={()=>filtrarPorFecha(fechaComboList.value)}>Filtrar</button>
                            </form>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <ExportTable fileName={"SubastasRealizadas.csv"}>
                            <table className="table">
                                <thead>
                                <tr>
                                    <th className="col-1 text-left">Fecha</th>
                                    <th className="col text-left">Subasta</th>
                                    <th className="col-2"></th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    !subastas.length ?  (<tr><td className="text-left font-weight-bolder text-uppercase" colSpan={"3"}>No existen subastas para el criterio de busqueda.</td></tr>) : (<tr className="d-none"><td colSpan={"3"}></td></tr>)
                                }
                                {
                                    subastas.map((subasta,key)=> {
                                        return (
                                            <tr key={'s'+key}>
                                                <td className="text-left">{subasta.TASU_FECSUBASTA}</td>
                                                <td className="text-left">
                                                    <b>{subasta.TATE_EMISION}</b><br/>
                                                    <b>Fecha de Emisión: <span className="font-weight-light">{subasta.FECHA_EMISION}, </span> </b>
                                                    <b>Fecha de Vencimiento: <span className="font-weight-light">{subasta.FECHA_VENCIMIENTO}, </span></b>
                                                    <b>Fecha de Liquidación: <span className="font-weight-light">{subasta.FECHA_LIQUIDACION} </span> </b>
                                                    <span className="d-block font-weight-lighter">{subasta.REFERENCIA}</span>
                                                </td>
                                                <td className="text-left">
                                                    <div className="btn-group d-none d-lg-flex" role="group" aria-label="Basic example">
                                                        <button type="button" className="btn btn-primary btn-sm mr-1" onClick={()=> {mostrarOfertas(subasta)}}>Ofertas</button>
                                                        <button type="button" className="btn btn-dark btn-sm" onClick={()=> {mostrarDetalle(subasta)}}>Resultados</button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </table>
                        </ExportTable>
                    </div>
                </div>
            </div>
            <OfertasRecibidas show={showOfertModal} onClose={()=> {setShowOfertModal(false)}} subasta={subataSelecionada} ></OfertasRecibidas>
            <SubastasResultados show={showDetalleModal} onClose={()=> {setShowDetalleModal(false)}} subasta={subataSelecionada} ></SubastasResultados>
        </>
    );
};


export default SubastasRealizadas;
