import React,{useEffect, useState} from 'react';
import {Modal, Spinner} from 'react-bootstrap';
import './Loader.scss'

const Loader = ({active=false}) => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    useEffect(() => {
        setShow(active);
        return () => {

        };
    }, [active]);


    return (
        <div>
            <Modal animation={false} dialogClassName="Loader" centered show={show} onHide={handleClose}>
                <Modal.Body>
                    <Spinner animation="border" />
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default Loader;
