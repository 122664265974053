import React, {useEffect} from 'react';
import {Tab, Tabs} from "react-bootstrap";
import SubastasRealizadas from "./SubastasRealizadas";
import SubastasActivas from "./SubastasActivas";
import Logo from "../Logo";
import Header from "../Header";

const Subastas = () => {


    useEffect(()=> {
        return ()=> {

        }
    },[]);

    return (
        <>
            <Header logo={<Logo link="/" style={{width:'400px', left:'-50px', position:'relative'}} />}/>
            {/*<h3 className="font-weight-light text-left">
                Subastas
            </h3>*/}
            <Tabs defaultActiveKey="realizadas"  className="mb-3 mt-4">
                <Tab eventKey="realizadas" title="Subastas Realizadas">
                    <SubastasRealizadas></SubastasRealizadas>
                </Tab>
                <Tab eventKey="activas" title="Subastas Activas">
                    <SubastasActivas></SubastasActivas>
                </Tab>
            </Tabs>
        </>
    );
};


export default Subastas;