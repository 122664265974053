import React from 'react';
import config from "../config.js";


class Api {
    constructor() {
        this.endpoint = config.host;
    }

    get(path) {
        return fetch(this.endpoint+path).then(result=> result.json());
    }
}

export default Api;
