import React from 'react';

const Rendimiento = ({value}) => {
    return (
        <>
            {Number(value).toFixed(3)}%
        </>
    );
};

export default Rendimiento;
