import React,{useEffect} from 'react';
import {Modal, Tab, Tabs} from "react-bootstrap";
import Logo from "../Logo";
import Monto from "../Monto";
import Precio from "../Precio";
import OfertasResultados from "./OfertasResultados";
import Adjudicaciones from "./Adjudicaciones";

const SubastasResultados = ({subasta, show, onClose}) => {
    useEffect(() => {
        return () => {

        };
    }, [subasta]);

    return (
        <>
            <Modal backdropClassName={"modal-backdrop-fullscreen"} dialogClassName={"modal-fullscreen SubastaResultadoModal"} show={show} onHide={onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div className="row">
                            <div className="col-lg-4 col-sm-12">
                                <Logo link="/" style={{width:'350px', left:'-27px', position:'relative'}} />
                            </div>
                            <div className="col-lg-8 col-sm-12">
                                <div>Resultados de la Subasta:</div>
                                <div style={{fontSize:'14px'}}>
                                    <b>{subasta?.TATE_EMISION}</b><br/>
                                    <b>Fecha de Emisión: <span className="font-weight-light">{subasta?.FECHA_EMISION}, </span> </b>
                                    <b>Fecha de Vencimiento: <span className="font-weight-light">{subasta?.FECHA_VENCIMIENTO}, </span></b>
                                    <b>Fecha de Liquidación: <span className="font-weight-light">{subasta?.FECHA_LIQUIDACION} </span> </b>
                                    <span className="d-block font-weight-lighter">{subasta?.REFERENCIA}</span>
                                </div>
                            </div>
                        </div>


                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tabs defaultActiveKey="competitivas"  className="mb-3 mt-4">
                        <Tab eventKey="competitivas" title="Ofertas Competitivas">
                            <OfertasResultados subasta={subasta} tipoOfertas={'C'}></OfertasResultados>
                        </Tab>
                        <Tab eventKey="no_competitivas" title="Ofertas No Competitivas">
                            <OfertasResultados subasta={subasta} tipoOfertas={'N'}></OfertasResultados>
                        </Tab>
                        <Tab eventKey="adjudicaciones" title="Resultados de la Adjudicación">
                            <Adjudicaciones subasta={subasta}></Adjudicaciones>
                        </Tab>
                    </Tabs>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default SubastasResultados;
