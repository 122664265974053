import React, {useEffect, useState} from 'react';

const Sector = ({value}) => {
    const [sector, setSector] = useState('');
    useEffect(() => {
        if(sector === 'P') {
            setSector('Público');
        } else {
            setSector('Privado');
        }
        return () => {
            
        };
    }, [value]);
    
    return (
        <>
            {sector}
        </>
    );
};

export default Sector;
