import React,{useState, useEffect} from 'react';

const TaseTipo = ({tipo, sector}) => {

    const [message, setMessage] = useState('');
    useEffect(() => {
        let text = '';
        if(tipo=== 'N') {
            text +='No Competitiva';
        } else {
            text +='Competitiva';
        }

        if(sector=== 'G') {
            text += ' Pública';
        } else {
            text += ' Privada';
        }

        setMessage(text);
        return () => {
        };
    }, [tipo, sector]);
    
    return (
        <div>
            {message}
        </div>
    );
};

export default TaseTipo;
