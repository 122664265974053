import React from 'react'
import Header from "../Header"
import './Home.scss'
import Logo from "../Logo";
import Bg from './subasta_bg.png';
import {Link} from "react-router-dom";

const Home = () => {
    return (
        <div className="Home">
            <Header/>

            <div className="container">
                <div className="row">
                    <div className="col message-container">
                        <div className="message">
                            <Logo className="mb-4 logo" />
                            <h3 className="font-weight-light mt-3">Sistema de Subastas</h3>
                            <p>Bienvenido a las Subastas Corporativas de la Bolsa Latinoamericana de Valores.</p>
                            <div>
                                <Link to="/subastas_realizadas" style={{backgroundColor:'#244c7c'}} className="btn btn-primary btn-lg active mr-2 mb-2" role="button" aria-pressed="true">
                                    Subastas Realizadas
                                </Link>
                                <Link to="/calendario" style={{backgroundColor:'#4494d3', borderColor:'#4494d3'}} className="btn btn-secondary btn-lg mb-2 active" role="button" aria-pressed="true">
                                    Calendario
                                </Link>
                            </div>
                        </div>

                        <div className="copyright mt-3">
                            <p className="font-weight-lighter">
                                Al accesar a este sistema, la persona o institución acepta y consiente que la información allá contenida es para fines estadísticos exclusivamente y que la misma no sustituye o modifica la información oficial escrita que la BOLSA LATINOAMERICANA DE VALORES suministra a sus usuarios.
                                <br/>
                                BOLSA LATINOAMERICANA DE VALORES no sera responsable por los errores, omisiones o faltas ni por la impropia o incompleta trasmisión de la información o por alguna demora en su recepción o daño a su sistema electrónico.
                                <br/>
                                BOLSA LATINOAMERICANA DE VALORES no garantiza que la integridad de la información ha sido mantenida ni que ella esta libre de virus, intercepción o interferencia.
                                <br/><br/>
                                Términos COPYRIGHTS {new Date().getFullYear()} BOLSA LATINOAMERICANA DE VALORES.
                            </p>
                        </div>
                    </div>

{/*                    <div className="col">
                        <div className="bg">
                            <img src={Bg} alt="Bg" />
                        </div>
                    </div>*/}
                </div>
            </div>
        </div>
    );
};

export default Home;