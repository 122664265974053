import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import Inicio from "./Home";
import Subastas from "./Subastas";
import Calendario from "./calendario";

export default function Routes() {
    return (
        <Router basename={'/PUBLICO'}>
            <Switch>
                <Route exact path={`/`}>
                    <Inicio />
                </Route>
                <Route path={`/subastas_realizadas`}>
                    <Subastas />
                </Route>
                <Route path={`/calendario`}>
                    <Calendario />
                </Route>
            </Switch>
        </Router>
    );
}