import React,{useEffect, useState} from 'react';

const Monto = ({value, tipoEmision}) => {

    const [monto, setMonto] = useState(0);

    useEffect(() => {
        const monto = value ? (tipoEmision === 'A' ? '' :'B/. ')+new Intl.NumberFormat('en-US').format(value): '';
        setMonto(monto);
        return () => {

        };
    }, []);


    return (
        <>
            <span>{monto}</span>
        </>
    );
};

export default Monto;
