import React, {useState} from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import Header from "../Header";
import Logo from "../Logo";
import calendario from './calendario1.pdf';
import './calendario.scss';

const options = {

};

const Calendario = () => {
    const [numPages, setNumPages] = useState(null);

    function onDocumentLoadSuccess({ numPages: nextNumPages }) {
        setNumPages(nextNumPages);
    }

    return (
        <div className={"container m-auto"}>
            <Header logo={<Logo link="/" style={{width:'400px', left:'-50px', position:'relative'}} />}/>
            <Document
                file={calendario}
                renderMode={'svg'}
                onLoadSuccess={onDocumentLoadSuccess}
                options={options}
            >
                {
                    Array.from(
                        new Array(numPages),
                        (el, index) => (
                            <Page
                                size="A4"
                                key={`page_${index + 1}`}
                                pageNumber={index + 1}
                            />
                        ),
                    )
                }
            </Document>
        </div>
    );
};

export default Calendario;
